import request from '@/utils/request2'

// 获取当日报工数据接口：api/
export function getCurDayBgData (params) {
  return request({
    url: '/api/wxjj/getCurDayBgData',
    method: 'get',
    params: params
  })
}

// 自动掉落件-工件下拉项目
export function getBggjList (params) {
  return request({
    url: '/api/wxjj/getBggjList',
    method: 'get',
    params: params
  })
}

// 自动掉落件-工序下拉项目
export function getBggxList (params) {
  return request({
    url: '/api/wxjj/getBggxList',
    method: 'get',
    params: params
  })
}

// 提交报工数量 
export function postOneToolsNum (params) {
  return request({
    url: '/api/wxjj/postOneToolsNum',
    method: 'get',
    params: params
  })
}

// 获取历史报工数据接口（默认显示当天）
export function getHisEmpBgList (params) {
  return request({
    url: '/api/wxjj/getHisEmpBgList',
    method: 'get',
    params: params
  })
}

// 自动掉落件-提交审核接口
export function postEmpDljBg (params) {
  return request({
    url: '/api/wxjj/postEmpDljBg',
    method: 'get',
    params: params
  })
}

// 修改历史报工数据接口
export function saveHisXgBg (params) {
  return request({
    url: '/api/wxjj/saveHisXgBg',
    method: 'get',
    params: params
  })
}

//------------审核
// 获取默认计产类别接口
export function getDefaultJxType (params) {
  return request({
    url: '/api/wxjj/getDefaultJxType',
    method: 'get',
    params: params
  })
}

// 获取待审核数据
export function getForCheckBgList (params) {
  return request({
    url: '/api/wxjj/getForCheckBgList',
    method: 'get',
    params: params
  })
}

// 批量审核
export function batchCheckBgList (data) {
  return request({
    url: '/api/wxjj/batchCheckBgList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 获取历史审核记录接口
export function getHisHasCheckList (params) {
  return request({
    url: '/api/wxjj/getHisHasCheckList',
    method: 'get',
    params: params
  })
}

// 修改单笔报工记录数量
export function saveBgNum (params) {
  return request({
    url: '/api/wxjj/saveBgNum',
    method: 'get',
    params: params
  })
}

// 首页点击按钮接口
export function beforeClickBtn (params) {
  return request({
    url: '/api/wxjj/beforeClickBtn',
    method: 'get',
    params: params
  })
}

// 获取当天集体报工数据接口
export function getCurDayGroupBgList (params) {
  return request({
    url: '/api/wxjj/getCurDayGroupBgList',
    method: 'get',
    params: params
  })
}

// 集体报工提交接口
export function postGroupBg (params) {
  return request({
    url: '/api/wxjj/postGroupBg',
    method: 'get',
    params: params
  })
}

// 获取集体报工历史数据接口
export function getHisGroupBgList (params) {
  return request({
    url: '/api/wxjj/getHisGroupBgList',
    method: 'get',
    params: params
  })
}

// 修改历史报工数据接口
export function saveHisGroupBg (params) {
  return request({
    url: '/api/wxjj/saveHisGroupBg',
    method: 'get',
    params: params
  })
}

// 获取当月工作时长记录接口
export function getCurMonthWorkTimeMaster (params) {
  return request({
    url: '/api/wxjj/getCurMonthWorkTimeMaster',
    method: 'get',
    params: params
  })
}

// 提交工时数据接口
export function postWorkTimeData (params) {
  return request({
    url: '/api/wxjj/postWorkTimeData',
    method: 'get',
    params: params
  })
}

// 获取人员明细 
export function getWorkTimeEmpList (params) {
  return request({
    url: '/api/wxjj/getWorkTimeEmpList',
    method: 'get',
    params: params
  })
}

// 提交人员工时明细接口
export function postWorkTimeDetail (data) {
  return request({
    url: '/api/wxjj/postWorkTimeDetail',
    method: 'post',
    data: data
  })
}

// 修改单笔待审核记录的合格数量 
export function saveForCheckHgNum (params) {
  return request({
    url: '/api/wxjj/saveForCheckHgNum',
    method: 'get',
    params: params
  })
}

// 获取当前用户默认字段值
export function getCurUserInfo (params) {
  return request({
    url: '/api/wxjj/getCurUserInfo',
    method: 'get',
    params: params
  })
}

// 暂存接口
export function saveZsInputData (params) {
  return request({
    url: '/api/wxjj/saveZsInputData',
    method: 'post',
    data: params
  })
}

// 获取注塑记录主表和子表记录接口
export function getZsInputData (params) {
  return request({
    url: '/api/wxjj/getZsInputData',
    method: 'get',
    params: params
  })
}

/**
 * 总装接口
 */

// 获取生产订单号列表接口 
export function getScDdList (params) {
  return request({
    url: '/api/wxjj/getScDdList',
    method: 'get',
    params: params
  })
}

// 根据生产订单号获取相关信息
export function getScDdExitValue (params) {
  return request({
    url: '/api/wxjj/getScDdExitValue',
    method: 'get',
    params: params
  })
}

// 暂存总装数据接口
export function saveZzInputData (params) {
  return request({
    url: '/api/wxjj/saveZzInputData',
    method: 'post',
    data: params
  })
}

// 返回总装主表及3个子表数据接口
export function getZzInputData (params) {
  return request({
    url: '/api/wxjj/getZzInputData',
    method: 'get',
    params: params
  })
}

// 新增一笔生产明细记录
export function addZzScmxRecord (params) {
  return request({
    url: '/api/wxjj/addZzScmxRecord',
    method: 'post',
    data: params
  })
}

// 修改一笔生产明细记录
export function saveZzScmxRecord (params) {
  return request({
    url: '/api/wxjj/saveZzScmxRecord',
    method: 'post',
    data: params
  })
}

// 删除多笔生产明细记录
export function deleteZzScmxRecord (params) {
  return request({
    url: '/api/wxjj/deleteZzScmxRecord',
    method: 'get',
    params: params
  })
}

// 新增一笔人员明细记录
export function addZzEmpRecord (params) {
  return request({
    url: '/api/wxjj/addZzEmpRecord',
    method: 'post',
    data: params
  })
}

// 修改一笔人员明细记录
export function saveZzEmpRecord (params) {
  return request({
    url: '/api/wxjj/saveZzEmpRecord',
    method: 'post',
    data: params
  })
}

// 删除多笔人员明细记录
export function deleteZzEmpRecord (params) {
  return request({
    url: '/api/wxjj/deleteZzEmpRecord',
    method: 'get',
    params: params
  })
}

// 提交总装数据接口
export function postZzInputData (params) {
  return request({
    url: '/api/wxjj/postZzInputData',
    method: 'get',
    params: params
  })
}

// 提交接口
export function postZsInputData (params) {
  return request({
    url: '/api/wxjj/postZsInputData',
    method: 'get',
    params: params
  })
}

// 获取待审批列表接口
export function getTmForCheckList (params) {
  return request({
    url: '/api/wxjj/get_Tm_ForCheckList',
    method: 'get',
    params: params
  })
}
// 获取待提交列表接口
export function getTmForPostList (params) {
  return request({
    url: '/api/wxjj/get_Tm_ForPostList',
    method: 'get',
    params: params
  })
}
// 获取已审批列表接口
export function getTmHasCheckList (params) {
  return request({
    url: '/api/wxjj/get_Tm_HasCheckList',
    method: 'get',
    params: params
  })
}
// 获取不同意列表接口
export function getTmNotAgreeList (params) {
  return request({
    url: '/api/wxjj/get_Tm_NotAgreeList',
    method: 'get',
    params: params
  })
}

// 同意单据接口
export function agreeTmList (params) {
  return request({
    url: '/api/wxjj/agreeTmList',
    method: 'get',
    params: params
  })
}

// 不同意单据接口

export function notAgreeTmlist (params) {
  return request({
    url: '/api/wxjj/notAgreeTmlist',
    method: 'get',
    params: params
  })
}